import { Show, Suspense, useContext, Match, Switch, createSignal, createMemo, For } from 'solid-js';
import {
	Button,
	Link,
	Picture,
	Container,
	DialogTrigger,
	Dialog,
	Heading,
	DialogContent,
	TextLink,
	Tag,
} from '@troon/ui';
import { Meta, Title } from '@solidjs/meta';
import { useAction } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { FacilityCtx } from '../../../providers/facility';
import { Grid, GridMain, GridSidebar } from '../../../components/layouts/grid';
import { Carousel } from '../../../components/carousel';
import { FacilityType, gql, mutationAction, useMutation } from '../../../graphql';
import { getFacilitySchema } from '../../../modules/schema/facility';
import { FacilityLocation } from '../../../components/facility/location';
import { Hero } from '../../../components/hero/photo';
import { courseTypeString } from '../../../components/facility/type-strings';
import type { RouteDefinition } from '@solidjs/router';
import type { Address, Course, Facility } from '../../../graphql';

export default function Course() {
	const facility = useContext(FacilityCtx);
	const handleFavoriteAction = useAction(useMutation(handleFavorite));
	const [openNonBookableDialog, setOpenNonBookableDialog] = createSignal(false);

	const supports = createMemo(() => {
		return Array.from(
			facility()?.facility.courses.reduce((memo, course) => {
				if (course.supportsTroonAccess) {
					memo.add('Troon Access');
				}
				if (course.supportsTroonRewards) {
					memo.add('Troon Rewards');
				}
				return memo;
			}, new Set<string>()) ?? [],
		);
	});

	return (
		<>
			<Show when={facility()?.facility}>
				{(facility) => (
					<>
						<Title>{`${facility()?.name} | Troon Rewards | Book tee times`}</Title>
						<Meta
							name="description"
							content={`${facility()?.metadata?.description?.slice(0, 154) ?? 'Book tee times at your favorite courses with Troon'}…`}
						/>
						<script type="application/ld+json" innerText={getFacilitySchema(facility() as Facility)} />
					</>
				)}
			</Show>

			<Suspense fallback={<div>loading…</div>}>
				<Show when={facility()?.facility}>
					{(facility) => (
						<>
							<Hero src={facility().metadata?.hero?.url} crop="entropy" />
							<Container>
								<Show when={facility().metadata?.logo?.url}>
									{(logo) => (
										<div class="relative -top-32 z-20 -mb-32 aspect-square size-36 rounded border border-neutral bg-white p-4 shadow">
											<Picture src={logo()} alt="" mode="contain" sizes={[[100, 100]]} class="w-full" />
										</div>
									)}
								</Show>
								<header class="mb-6 flex flex-wrap items-center justify-start gap-4 border-b border-neutral-500 pb-6 md:mb-8 md:flex-nowrap md:pb-8 lg:gap-8">
									<div class="flex shrink grow flex-col gap-2 md:gap-4">
										<Heading as="h1">{facility().name}</Heading>
										<p class="order-first uppercase text-neutral-800">
											<span class="sr-only">Course type:</span> {courseTypeString[facility().type]}
										</p>
										<Show when={facility().metadata?.address as Address}>
											{(address) => (
												<address
													class="text-sm not-italic text-neutral-800"
													aria-label={`Address for ${facility().name}`}
												>
													{[address().street, address().city, [address().state, address().postalCode].join(' ')]
														.filter(Boolean)
														.join(', ')}
												</address>
											)}
										</Show>
										<Show when={supports().length}>
											<ul class="flex gap-4">
												<For each={supports()}>
													{(support) => (
														<li>
															<Tag>
																<Icon name="check" title="Supports" /> {support}
															</Tag>
														</li>
													)}
												</For>
											</ul>
										</Show>
									</div>

									<div class="flex grow basis-full flex-row justify-end gap-4 md:grow-0 md:basis-auto">
										<Show when={[FacilityType.DailyFeeResort, FacilityType.SemiPrivate].includes(facility().type)}>
											<div class="grow lg:grow-0">
												<Show
													when={!facility().isBookable}
													fallback={
														<Button
															as={Link}
															href={`/course/${facility().slug!}/reserve-tee-time`}
															class="shrink text-nowrap"
														>
															Book a tee time
														</Button>
													}
												>
													<Dialog
														key="non-bookable-facility"
														open={openNonBookableDialog()}
														onOpenChange={setOpenNonBookableDialog}
													>
														<DialogTrigger appearance="primary">Book a tee time</DialogTrigger>
														<DialogContent header="Book a Tee Time" headerLevel="h2">
															<div class="flex flex-col gap-4">
																<Picture
																	src={facility().metadata?.hero?.url}
																	sizes={[[520, 153]]}
																	alt=""
																	class="overflow-hidden rounded"
																/>
																<div class="flex flex-col gap-1">
																	<p class="text-xl font-semibold">{facility().name}</p>
																	<p class="order-first text-sm uppercase text-neutral-800">
																		<span class="sr-only">Course type:</span> {courseTypeString[facility().type]}
																	</p>
																	<Show when={facility().metadata?.address as Address}>
																		{(address) => (
																			<address
																				class="text-sm not-italic text-neutral-800"
																				aria-label={`Address for ${facility().name}`}
																			>
																				{[
																					address().street,
																					address().city,
																					[address().state, address().postalCode].join(' '),
																				].join(', ')}
																			</address>
																		)}
																	</Show>
																</div>

																<p class="rounded bg-neutral-100 p-4">
																	This course is currently unavailable to book online. To book a tee time please call
																	the number below:
																</p>

																<Show when={facility().metadata?.phone}>
																	{(phone) => (
																		<div class="relative flex justify-between rounded border border-neutral px-4 py-6">
																			<div class="flex flex-col">
																				<strong>Phone</strong>
																				<TextLink href={`tel:${phone()}`} class="after:absolute after:inset-0">
																					{phone()}
																				</TextLink>
																			</div>
																			<span class="flex size-10 items-center justify-center rounded bg-brand-100 p-1 text-xl text-brand">
																				<Icon name="phone" />
																			</span>
																		</div>
																	)}
																</Show>
															</div>
														</DialogContent>
													</Dialog>
												</Show>
											</div>
										</Show>
										<div class="shrink">
											<Button
												appearance="secondary"
												onClick={() => {
													const data = new FormData();
													data.set('facilityId', facility().id ?? '');
													data.set('isFavorite', facility().isFavorite ? 'false' : 'true');
													handleFavoriteAction(data);
												}}
											>
												<Switch>
													<Match when={facility().isFavorite}>
														<Icon name="star-filled" class="text-xl" />
													</Match>
													<Match when>
														<Icon name="star" class="text-xl" />
													</Match>
												</Switch>
												<span class="sr-only">Favorite</span>
											</Button>
										</div>
									</div>
								</header>

								<Grid>
									<GridMain class="flex flex-col gap-8">
										<section class="flex flex-col gap-4">
											<h2 class="text-xl font-semibold md:text-2xl">Overview</h2>
											<p innerHTML={facility().metadata?.description ?? ''} />
										</section>

										<Show when={facility().metadata?.gallery.length}>
											<section class="flex flex-col gap-4">
												<h2 class="text-xl font-semibold md:text-2xl">Course photos</h2>
												<Carousel
													images={facility().metadata!.gallery.map(({ url }) => ({
														url,
														alt: `Landscape putting greens and fairway photo of the ${facility().name} golf course`,
													}))}
												/>
											</section>
										</Show>
									</GridMain>

									<GridSidebar>
										<div class="flex flex-col gap-8">
											<FacilityLocation facility={facility()} />
										</div>
									</GridSidebar>
								</Grid>
							</Container>
						</>
					)}
				</Show>
			</Suspense>
		</>
	);
}

const toggleFavorite = gql(`
mutation toggleFavoriteFacility($facilityId: String!, $isFavorite: Boolean!) {
	toggleFavoriteFacility(facilityId: $facilityId, isFavorite: $isFavorite) {
		name
		isFavorite
	}
}
`);

const handleFavorite = mutationAction(toggleFavorite, {
	transform: (data) => ({
		facilityId: data.get('facilityId') as string,
		isFavorite: data.get('isFavorite') === 'true',
	}),
	toast: (data) =>
		data?.toggleFavoriteFacility.isFavorite
			? `Added ${data?.toggleFavoriteFacility.name} as a favorite`
			: `Removed ${data?.toggleFavoriteFacility.name} as a favorite.`,
	revalidates: ['facility', 'home'],
});

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
